import { useCallback, useRef, useState } from "react"

export const useParallax = function () {
  const container = useRef(0)

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })

  const onMouseMoveCaptureHandler = useCallback(event => {
    const { clientWidth, clientHeight } = container.current
    event.persist()
    const xh = clientWidth / 2
    const yh = clientHeight / 2
    const x = Math.round((event.clientX - xh) / 100)
    const y = Math.round((event.clientY - yh) / 100)
    setCoordinates(prevState => ({ ...prevState, x: x, y: y }))
  }, [])

  return [container, coordinates, onMouseMoveCaptureHandler]
}
