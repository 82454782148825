const COLORS = {
  primary: "#222",
  secondary: "#f5f5f5",
  yellow: "#fcbe29",
  offWhite: "#e7e7e7",
  extraLightGray: "#d6d6d6",
  lightGray: "#979797",
  gray: "#808080",
  darkGray: "#282828",
  darkerGray: "#1f1e1e",
  white: "#ffffff",
  black: "#000000",
  lightPink: "#f5f0ec",
  captionColor: "#d5d5d5",
  btnBgColor: "#333",
  btnBorderBlue: "#007ced",
  btnBorderDefault: "#d5d5d5",
  error: "#ff4e4e",
}

const THEME = {
  color: {
    primary: COLORS.primary,
    secondary: COLORS.secondary,
    darkerBackground: COLORS.darkerGray,
    darkBackground: COLORS.darkGray,
    darkBackgroundHover: COLORS.darkerGray,
    darkBackgroundSecondary: COLORS.gray,
    darkBackgroundHoverSecondary: COLORS.offWhite,
    lightBackground: COLORS.lightPink,
    blackBackground: COLORS.black,
    captionColor: COLORS.captionColor,
    btnBgColor: COLORS.btnBgColor,
    btnBorderBlue: COLORS.btnBorderBlue,
    btnBorderDefault: COLORS.btnBorderDefault,
    inputBorder: COLORS.offWhite,
    inputPlaceholder: COLORS.lightGray,
    fontColor: COLORS.darkerGray,
    fontColorBlack: COLORS.black,
    fontColorInverted: COLORS.white,
    fontColorSecondary: COLORS.lightGray,
    buttonBorder: COLORS.darkerGray,
    separator: COLORS.offWhite,
    darkSeparator: COLORS.lightGray,

    badge: COLORS.yellow,
    source: COLORS.secondary,
    error: COLORS.error,

    placeholderBackground: COLORS.extraLightGray,
  },
  typography: {
    h1: 40,
    h2: 16,
    h3: 20,
    p: 20,
    caption: 14,
    info: 12,
    span: 20,
  },
}

export default THEME
