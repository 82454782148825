export default `
body{
margin:0;
background-color: #007ced;
font-family: "Poppins",Helvetica,sans-serif;
user-select: none;

a{
color: #f5f5f5;
}
}

.carousel-dots-active::before{
color:red;
}

.carousel-row {
cursor:pointer;
}

.carousel-row > div:focus{
outline:none; 
cursor:pointer;
}


.carousel-dots li.carousel-dots-active button:before{
color:#007ced !important;
font-size:60px !important;
}
.carousel-dots li button:before{
color:white;
}



[data-aos="custom-animation"] {
  width:0;
  transition-property: transform, width;

  &.aos-animate {
    width:100%;
  }
  
}

.mapouter{position:relative;text-align:right;height:200px;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;height:200px;width:100%;}

.box{
box-shadow: 0px 1px 5px 0px rgba(0,124,237,0.95);
}

.nav-shadow{
box-shadow: 0px 0px 10px 0px rgba(0,124,237,.88);
}

 

`
