import React, { memo } from "react"
import { Global, css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import normalize from "../styles/normalize"
import THEME from "../constants/themes.constants"
import styled from "@emotion/styled"
import bgImage from "../assets/images/main_bg_14.png"
import { useParallax } from "../hooks/useParallax"

const StyledBgContainer = styled.div`
  background-image: url(${bgImage});
  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  left: -10px;
  right: -10px;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: ${({ x, y }) =>
    css` calc(50% + ${x}px) calc(50% + ${y}px)`};
`

const StyledScrollPage = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  vertical-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
  backface-visibility: hidden;
`

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  flex-wrap: wrap;
  max-width: 1320px;
  border-radius: 40px;
  background-color: #222;
  margin: 3% auto;
  padding: 40px 70px 60px;

  @media only screen and (max-width: 970px) {
    width: 100%;
    margin: 0;
    padding: 40px 40px 60px;
    border-radius: 0;
  }
`

const LayoutRoot = ({ children }) => {
  const [container, coordinates, onMouseMoveCaptureHandler] = useParallax()

  return (
    <ThemeProvider theme={THEME}>
      <Global styles={() => css(normalize)} />
      <StyledBgContainer x={coordinates.x} y={coordinates.y} />
      <StyledScrollPage
        ref={container}
        onMouseMoveCapture={event => onMouseMoveCaptureHandler(event)}
      >
        <StyledPageContainer>{children}</StyledPageContainer>
      </StyledScrollPage>
    </ThemeProvider>
  )
}

export default memo(LayoutRoot)
